import Vue from "vue";
import Bus from "@/utils/events/bus";

import { roundValor } from "@/app/global";
import { carrinhoAtual } from "./getters";

import { updateStageCampanha } from "../utils/campanhas/updateStageCampanha";

const dpcAxios = require("../dpcAxios");

export default {
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },

  setInformacaoGlobal(state, item) {
    state.informacao_global = item;
  },

  setSiteConfig(state, item) {
    state.siteConfig = item;
  },

  setToken(state, item) {
    state.token = item;
  },

  setCampanhasDisponiveis(state, item) {
    // console.log(item);
    state.campanhasDisponiveis = item;

    // Vue.set(state.campanhasDisponiveis, 0, item);
  },
  setHasMoreCampaignsToShow(state, item) {
    state.hasMoreCampaignsToShow = item;
  },
  setCampanhaStatus(state, item) {
    if (!Array.isArray(item) && typeof item != "object") return;

    const campanhasAtivadas = [];

    const campanhas = item;
    campanhas.forEach((campanha) => {
      const stageCampanha = updateStageCampanha(campanha);
      if (!stageCampanha) return;
      campanhasAtivadas[stageCampanha.id] = stageCampanha;
    });

    state.campanhaStatus = campanhasAtivadas;
  },

  logoff(state, item) {
    state.token = "";
  },

  setUser(state, item) {
    state.user = item;
  },

  setDadosPedido(state, item) {
    state.dadosPedido = item;
  },

  paymentData(state, item) {
    state.paymentData = item;
  },
  removeStoredCard(state, cardId) {
    const cardIdx = state.saveCards.findIndex(
      (cards) => cards.card_id == cardId
    );
    if (cardIdx > -1) {
      state.saveCards.splice(cardIdx, 1);
    }
  },
  totalProduct(state, item) {
    state.totalProduct = item;
  },

  faturamentoData(state, item) {
    state.faturamentoData = item;
  },
  saveCards(state, item) {
    state.saveCards = item;
  },

  savedCardSelected(state, item) {
    state.savedCardSelected = item;
  },

  cvvCardSave(state, item) {
    state.cvvCardSave = item;
  },

  globalAppLoading(state, item) {
    state.globalAppLoading = item;
  },

  cardPaymentError(state, item) {
    state.cardPaymentError = item;
  },
  setUsuarioInfo(state, item) {
    state.usuarioInfo = item;
  },
  setUserShouldResetPassword(state, item) {
    state.userShouldResetPassword = item;
  },
  async addItemCarrinhoAtual(state, item) {
    if (item != "shouldCleanCart") {
      const {
        pedido_id,
        cod_produto,
        integracao_id,
        qtd,
        qtdestoque,
        descricao,
        valor,
        vlrst,
        vlrdesconto,
        vlrstdesonerado,
        src,
        status,
        busca,
        lastUpdate = 0,
        desconto_campanha,
        campanhasAtivas,
        haspromocaocampanha,
        codigo_exibicao,
        decim,
        decim_num_casas,
      } = item;

      //  Verifica se item adicionado está no state
      const itemAdicionado = state.carrinhoAtual.find(
        (p) => p.cod_produto === cod_produto
      );

      if (!itemAdicionado) {
        if (qtd != 0) {
          // Adiciona novo item no array
          state.carrinhoAtual.push({
            cod_produto,
            integracao_id,
            codigo_exibicao,
            qtd,
            descricao,
            src,
            valor: roundValor(valor),
            vlrst: roundValor(vlrst),
            status,
            vlrdesconto: roundValor(vlrdesconto),
            vlrstdesonerado: roundValor(vlrstdesonerado || 0),
            qtdestoque,
            lastUpdate,
            desconto_campanha,
            campanhasAtivas,
            haspromocaocampanha,
            decim,
            decim_num_casas,
          });
        }
      } else if (qtd == 0) {
        itemAdicionado.qtd = 0;
        // Exclui produto do store se a qtd for 0
        state.carrinhoAtual = state.carrinhoAtual.filter(
          (item) => item.cod_produto != cod_produto
        );
      } else {
        // Somente atualiza quantidade de item já no array
        itemAdicionado.valor = roundValor(valor);
        itemAdicionado.vlrst = roundValor(vlrst);
        itemAdicionado.vlrdesconto = roundValor(vlrdesconto);
        itemAdicionado.vlrstdesonerado = roundValor(vlrstdesonerado || 0);
        itemAdicionado.qtd = qtd;
        itemAdicionado.lastUpdate = lastUpdate;
        itemAdicionado.desconto_campanha = desconto_campanha;
        itemAdicionado.campanhasAtivas = campanhasAtivas;
        itemAdicionado.haspromocaocampanha = haspromocaocampanha;
        itemAdicionado.decim = decim;
        itemAdicionado.decim_num_casas = decim_num_casas;
      }
    } else {
      // Clean carrinho
      state.carrinhoAtual = [];
    }
  },

  searchProductsBonus(state, item) {
    state.productsWithCampanha = item;
  },

  setClienteAtual(state, item) {
    state.clienteAtual = item;
  },

  async searchDiscountProducts(state, item) {
    const pedidoId =
      localStorage.getItem("pedidoaberto") || state.dadosPedido.pedidoId;

    if (!pedidoId) return;

    const response = await dpcAxios
      .connection()
      .post(
        process.env.VUE_APP_ECOMMERCE +
          api.env.BUSCA_PRODUTOS_DESCONTOS +
          pedidoId
      );

    if (response.data.error == 0) {
      const carrinhoAtualAux = state.carrinhoAtual;
      const produtosDesconto = response.data.dados;

      // // Remove o desconto de todas as campanhas primeiro.
      // carrinhoAtualAux.forEach((item) => {
      //   item.desconto_campanha = 0;
      //   item.haspromocaocampanha = false;
      //   // item.vlrdesconto = 0; -- interfere nos outros descontos. Precisamos ver umaf orma de corrigir.
      // });

      carrinhoAtualAux.forEach((item) => {
        if (produtosDesconto[item.integracao_id]) {
          item.desconto_campanha =
            produtosDesconto[item.integracao_id].desconto_campanha;
          item.vlrdesconto = produtosDesconto[item.integracao_id].vlrdesconto;
          item.qtd_bonificado =
            produtosDesconto[item.integracao_id].qtd_bonificado;
          item.haspromocaocampanha =
            item.desconto_campanha > 0 || item.qtd_bonificado > 0;
          item.campanhasAtivas =
            produtosDesconto[item.integracao_id].campanhasAtivas;
          return item;
        }
      });
    } else {
    }
  },
  setShowLoadingModal(state, value) {
    state.showLoadingModal = value;
  },
  addLoadingModalId(state, id) {
    state.loadingModalIds.push(id);
  },
  removeLoadingModalId(state, id) {
    state.loadingModalIds = state.loadingModalIds.filter(
      (modalId) => modalId !== id
    );
  },
};
