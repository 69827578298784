var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timelineContainer",class:_vm.isFixed ? _vm.isFixed : ''},[(!_vm.todosGruposAtivados)?_c('div',{staticClass:"comboTitleContent"},[_c('span',[_vm._v(_vm._s(_vm.grupoProximoAtivacao.name))])]):_vm._e(),_c('div',{staticClass:"header"},[_c('div',{staticClass:"titleHead"},[_vm._m(0),_c('h3',[(
            !_vm.todosGruposAtivados &&
            _vm.grupoProximoAtivacao.qtdMinProd -
              _vm.grupoProximoAtivacao.qtdMinProdAtivados >
              0
          )?_c('span',[_vm._v(" "+_vm._s(`Adicione mais ${ _vm.grupoProximoAtivacao.qtdMinProd - _vm.grupoProximoAtivacao.qtdMinProdAtivados } produtos para ativar o combo.`)+" ")]):(
            !_vm.todosGruposAtivados &&
            _vm.grupoProximoAtivacao.vlrMinProd -
              _vm.grupoProximoAtivacao.vlrMinProdAtivados >
              0
          )?_c('span',[_vm._v(" "+_vm._s(`Adicione mais R$ ${_vm.mascaraValor( _vm.grupoProximoAtivacao.vlrMinProd - _vm.grupoProximoAtivacao.vlrMinProdAtivados )} em produtos para ativar o combo. `)+" ")]):(
            _vm.campanhaAtual.vlrMinCampanha - _vm.campanhaAtual.vlrProdAtivados > 0
          )?_c('span',[_vm._v(" "+_vm._s(`Adicione mais R$ ${_vm.mascaraValor( _vm.campanhaAtual.vlrMinCampanha - _vm.campanhaAtual.vlrProdAtivados )} em produtos para ativar o combo. `)+" ")]):(
            _vm.campanhaAtual.qtdMinCampanha - _vm.campanhaAtual.qtdProdAtivados > 0
          )?_c('span',[_vm._v(" "+_vm._s(`Adicione mais ${ _vm.campanhaAtual.qtdMinCampanha - _vm.campanhaAtual.qtdProdAtivados } produtos para ativar o combo.`)+" ")]):(
            _vm.campanhaAtual.vlrMaxCampanha &&
            _vm.campanhaAtual.vlrProdAtivados > _vm.campanhaAtual.vlrMaxCampanha
          )?_c('span',[_vm._v(" "+_vm._s(`Limite máximo de R$ ${_vm.mascaraValor( _vm.campanhaAtual.vlrMaxCampanha )} em produtos atingido. `)+" ")]):(
            _vm.campanhaAtual.qtdMaxCampanha &&
            _vm.campanhaAtual.qtdProdAtivados > _vm.campanhaAtual.qtdMaxCampanha
          )?_c('span',[_vm._v(" "+_vm._s(`Limite máximo de ${_vm.campanhaAtual.qtdMaxCampanha} produtos atingido.`)+" ")]):_c('span',[_vm._v(" Você ativou todos os combos! Aproveite os descontos :) ")])])]),(!_vm.todosGruposAtivados)?_c('div',{staticClass:"progressbar"},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"ballProgress",class:_vm.percentAnimated == 100 ? 'ballNone' : '',style:({ left: _vm.percentAnimated - 0.5 + '%' })}),_c('div',{staticClass:"line line-progressive",style:({ width: _vm.percentAnimated + '%' })}),_c('div',{staticClass:"line"})])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconDiscount"},[_c('i',{staticClass:"mdi mdi-sale-outline fa-3x"})])
}]

export { render, staticRenderFns }